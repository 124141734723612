import React from 'react';
import NavBar from 'cccisd-header';
import style from './style.css';

const Fortress = window.cccisd && window.cccisd.fortress;
const actingRole = Fortress.user.acting.role.handle;
const wpUrl = window.cccisd.env.wpUrl;

export default () => {
    const primaryNavLinks = [
        { url: wpUrl + '/about', label: 'About' },
        {
            url: wpUrl + '/programs',
            label: 'Programs',
            children: [
                { url: wpUrl + '/programs/choice/', label: 'CHOICE' },
                { url: wpUrl + '/programs/freetalk/', label: 'Free Talk' },
                { url: wpUrl + '/programs/micunay/', label: 'MICUNAY' },
                { url: wpUrl + '/programs/aware/', label: 'AWARE' },
            ],
        },
        { url: wpUrl + '/mi/resources/', label: 'Resources' },
        { url: wpUrl + '/faq/', label: 'FAQ' },
    ];

    return (
        <NavBar
            containerClassName="container-fluid"
            wrapperClassName={style.wrapper}
            logoClassName={style.logo}
            showAlertAction={false}
            primaryNavLinks={
                actingRole === 'uberadmin' ? [...primaryNavLinks, { url: '/manage', label: 'Manage' }] : primaryNavLinks
            }
            logoLink={wpUrl}
        />
    );
};
